import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import "./Sidebar.scss";
import { useAuth } from "./utils/AuthContext";

function Sidebar({ showTab = "repos" }) {
  const [isOpen, setIsOpen] = useState(true); // State to manage the sidebar visibility
  const toggleSidebar = () => setIsOpen(!isOpen); // Function to toggle the sidebar
  const { authUser, authLoading, signOut } = useAuth();

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  return (
    <>
      <button
        className="sidebar-toggle"
        style={{ visibility: isOpen ? "visible" : "hidden" }}
        onClick={toggleSidebar}
      >
        <i className="material-icons">menu</i>{" "}
        {/* This icon represents the hamburger menu */}
      </button>
      <div className={`sidebar ${isOpen ? "" : "collapsed"}`}>
        <div className="f-col cntnt">
          <div className="f-col upper">
            <h3 className="f-row aic jcsb">
              <Link className="navbar-brand" to="/">
                devlo
              </Link>
              <i
                className="material-icons in-sidebar-toggle"
                style={{ visibility: isOpen ? "hidden" : "visible" }}
                onClick={toggleSidebar}
              >
                chevron_left
              </i>
            </h3>
            <div className="fccc tabs">
              <Link
                to="/dashboard"
                className={
                  showTab === "repos" ? "f-row aic active" : "f-row aic"
                }
              >
                <i className="material-icons">code</i> Repositories
              </Link>
              <Link
                to="/orgs"
                className={
                  showTab === "orgs" ? "f-row aic active" : "f-row aic"
                }
              >
                <i className="material-icons">corporate_fare</i>
                Organizations
              </Link>
              <Link
                to="/guide"
                className={
                  showTab === "guide" ? "f-row aic active" : "f-row aic"
                }
              >
                <i className="material-icons">settings_applications</i>
                Guide
              </Link>
            </div>
          </div>
          <div className="f-row aic jcsb bottom">
            <div className="frcc user-sec">
              {!authLoading && authUser.avatarUrl ? (
                <>
                  <img
                    src={authUser.avatarUrl}
                    alt="User Avatar"
                    className="user-avatar navbar-avatar"
                  />
                  <h4>{authUser.name}</h4>
                </>
              ) : (
                <CircularProgress />
              )}
            </div>

            <button onClick={handleSignOut}>
              <i className="material-icons">logout</i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
