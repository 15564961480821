import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged, signOut as firebaseSignOut } from "firebase/auth";
import { auth } from "../../firebase-config";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [authUser, setAuthUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [firebaseUser, setFirebaseUser] = useState(null);

  useEffect(() => {
    // Initialize authUser from local storage if it exists
    const storedUser = localStorage.getItem("authUser");
    if (storedUser) {
      setAuthUser(JSON.parse(storedUser));
    }
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setFirebaseUser(currentUser);
      } else {
        // User is signed out
        setAuthUser(null);
        localStorage.removeItem("authUser");
      }
      setAuthLoading(false);
    });

    const handleStorageChange = (event) => {
      if (event.key === "authUser") {
        if (event.newValue) {
          setAuthUser(JSON.parse(event.newValue));
        } else {
          setAuthUser(null);
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      unsubscribe();
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate]);

  const signOut = () => {
    firebaseSignOut(auth)
      .then(() => {
        localStorage.removeItem("authUser");
        localStorage.setItem("logout", Date.now().toString());
        setFirebaseUser(null);
        setTimeout(() => localStorage.removeItem("logout"), 50);
        setAuthUser(null);
        if (window.location.pathname !== "/login") {
          navigate("/login");
        }
      })
      .catch((error) => {
        console.error("Logout Error:", error);
        if (window.location.pathname !== "/login") {
          navigate("/login");
        }
      });
  };

  const updateAuthUser = (updates) => {
    setAuthUser((prevAuthUser) => {
      const updatedUser = { ...prevAuthUser, ...updates };
      localStorage.setItem("authUser", JSON.stringify(updatedUser));
      return updatedUser;
    });
  };
  return (
    <AuthContext.Provider
      value={{
        authUser,
        authLoading,
        firebaseUser,
        updateAuthUser,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
