import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db, functions } from "../firebase-config";
import Sidebar from "../components/Sidebar";
import { Link } from "react-router-dom";
import "../components/Dashboard.scss";
import "./OrganizationDetail.scss";
import { fetchGitHubUser } from "../components/utils/githubApi";
import Loading from "../components/utils/Loading";
import LogoNavbar from "../components/utils/LogoNavbar";
import { httpsCallable } from "firebase/functions";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const OrganizationDetail = () => {
  const { orgId } = useParams();
  const [org, setOrg] = useState(null);
  const [seats, setSeats] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [fetchError, setFetchError] = useState("");
  const [showTab, setShowTab] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saveMessage, setSaveMessage] = useState("");
  const [initialAutoAssignSeats, setInitialAutoAssignSeats] = useState(true);

  useEffect(() => {
    const fetchOrganizationAndSeats = async () => {
      try {
        const orgRef = doc(db, "organizations", orgId);
        const orgDoc = await getDoc(orgRef);

        if (orgDoc.exists()) {
          const orgData = orgDoc.data();
          setOrg(orgData);

          // Use nullish coalescing to only default to true if the value is null/undefined
          const initialValue = orgData.autoAssignSeats ?? true;
          setInitialAutoAssignSeats(initialValue);

          const seatsRef = collection(db, `organizations/${orgId}/seats`);
          const seatsSnapshot = await getDocs(seatsRef);
          const seatsData = seatsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setSeats(seatsData);
          setShowTab(true);
        } else {
          setFetchError("Organization not found");
        }
      } catch (error) {
        setFetchError("Error fetching organization details");
      }
      setLoading(false);
    };

    fetchOrganizationAndSeats();
  }, [orgId]);

  const handleAddUser = useCallback(async () => {
    setError(""); // Reset error message
    if (!username) {
      setError("Username is required!");
      return;
    }

    try {
      const userDetails = await fetchGitHubUser(username);
      if (!userDetails.id) {
        setError(
          "User not found. Please check the GitHub username and try again."
        );
        return;
      }

      // Check if the GitHub ID already exists in the seats
      const existingUser = seats.find(
        (seat) => seat.userGithubId === userDetails.id
      );
      if (existingUser) {
        setError("User already exists.");
        return;
      }

      // Call Firebase Function
      const addSeat = httpsCallable(functions, "addSeat");
      const result = await addSeat({
        orgId,
        userGithubId: userDetails.id,
        userDetails,
      });
      console.log(result);

      const newUser = {
        userGithubId: userDetails.id,
        username: userDetails.login,
        htmlUrl: userDetails.html_url,
        avatarUrl: userDetails.avatar_url,
        lastUpdated: new Date(),
      };
      setSeats([...seats, newUser]); // Add new user details to the list
      setUsername(""); // Clear the input field
    } catch (error) {
      // Extract the suffix of the error code if it contains a prefix
      const errorCode = error.code?.split("/")[1] || error.code;
      switch (errorCode) {
        case "resource-exhausted":
          setError("The maximum number of seats has been reached.");
          break;
        case "already-exists":
          setError("This user already exists in your seats.");
          break;
        case "unauthenticated":
          setError("You must be logged in to perform this action.");
          break;
        default:
          setError("An unexpected error occurred: " + error.message);
      }
    }
  }, [orgId, seats, username]);

  const removeSeat = async (seatId) => {
    if (window.confirm("Are you sure you want to remove this seat?")) {
      try {
        await deleteDoc(doc(db, `organizations/${orgId}/seats`, seatId));
        setSeats((seats) =>
          seats.filter((seat) => seat.userGithubId.toString() !== seatId)
        );
      } catch (error) {
        console.error("Failed to remove seat:", error);
      }
    }
  };
  const onEnterPress = (e) => {
    if (e.key === "Enter" && username.trim()) {
      handleAddUser();
    }
  };

  const saveAutoAssignSetting = async () => {
    setSaving(true);
    setSaveMessage("");
    try {
      const orgRef = doc(db, "organizations", orgId);
      await updateDoc(orgRef, {
        autoAssignSeats: org.autoAssignSeats,
      });
      setIsDirty(false);
      setInitialAutoAssignSeats(org.autoAssignSeats); // Update initial state
      setSaveMessage("Settings saved successfully.");
    } catch (error) {
      console.error("Error updating auto-assign seats:", error);
      // Revert state on error
      setOrg((prevOrg) => ({
        ...prevOrg,
        autoAssignSeats: !org.autoAssignSeats,
      }));
      alert("Failed to save the setting. Please try again.");
    }
    setSaving(false);
  };

  return (
    <div className="f-row aifs dashboard">
      <Sidebar showTab="orgs" />
      {fetchError !== "" && (
        <div className="f-row aic page-error">
          <i className="material-icons open-icn">error</i> {fetchError}
        </div>
      )}
      {loading && <Loading />}
      {showTab && (
        <div className="main-outer">
          <div className="f-col aifs main org-deets">
            <LogoNavbar />
            <div className="frcc route">
              <Link to="/orgs">Organizations</Link>
              <i className="material-icons icn">navigate_next</i>
              {org && <span>{org.name}</span>}
            </div>
            <div className="f-row aic jcsb header">
              <div className="frcc lefty">
                <a
                  href={org.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="frcc title"
                >
                  <h1 className="f-row aifs">
                    {org.name}
                    <i className="material-icons open-icn">open_in_new</i>
                  </h1>
                </a>
                <div
                  className={`frcc status-badge ${
                    org.active ? "active" : "inactive"
                  }`}
                >
                  {org.active && <span className="pulsating-circle"></span>}
                  {org.active ? "Active" : "Inactive"}
                </div>
              </div>
              <a
                href={`https://github.com/settings/installations/${org.installationId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="cta-btn"
              >
                Modify Installation
              </a>
            </div>
            <p>
              You have used {seats.length} seat
              {seats.length > 1 && "s"}.
            </p>
            <div className="toggle-section">
              <h2>Settings</h2>
              <FormGroup>
                <FormControlLabel
                  label={
                    <div className="f-col aifs jcc">
                      <h4 style={{ margin: 0 }}>Auto-assign seats</h4>
                      <span className="description">
                        Automatically assign seats to new users when they
                        interact with repositories
                      </span>
                    </div>
                  }
                  control={
                    <Switch
                      checked={org.autoAssignSeats !== false}
                      onChange={(e) => {
                        const newValue = e.target.checked;
                        setOrg((prevOrg) => ({
                          ...prevOrg,
                          autoAssignSeats: newValue,
                        }));
                        setIsDirty(newValue !== initialAutoAssignSeats);
                      }}
                      name="auto-assign-seats-toggle"
                      color="primary"
                    />
                  }
                />
              </FormGroup>
              <div className="f-row aic save-cnt">
                <button
                  className={`save-btn ${isDirty ? "active" : "disabled"}`}
                  disabled={!isDirty || saving}
                  onClick={saveAutoAssignSetting}
                >
                  {saving ? "Saving..." : "Save"}
                </button>
                {saveMessage && (
                  <div className="success-message">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="31"
                      height="27"
                      viewBox="0 0 31 27"
                      fill="none"
                    >
                      <path
                        d="M21.0687 9.00907C21.3329 8.79167 21.6844 8.67148 22.049 8.67386C22.4137 8.67625 22.763 8.80102 23.0233 9.02186C23.2836 9.24269 23.4345 9.54232 23.4441 9.8575C23.4538 10.1727 23.3214 10.4788 23.075 10.7112L15.5937 18.8004C15.4651 18.9202 15.3098 19.0163 15.1372 19.083C14.9646 19.1498 14.7782 19.1857 14.5892 19.1887C14.4002 19.1918 14.2124 19.1618 14.037 19.1006C13.8617 19.0395 13.7024 18.9484 13.5687 18.8328L8.60749 14.5434C8.46933 14.4321 8.35851 14.2979 8.28165 14.1488C8.20479 13.9996 8.16346 13.8386 8.16013 13.6754C8.1568 13.5121 8.19153 13.35 8.26226 13.1986C8.33299 13.0472 8.43826 12.9097 8.57179 12.7942C8.70533 12.6788 8.86439 12.5878 9.03949 12.5266C9.2146 12.4655 9.40215 12.4354 9.59097 12.4383C9.77979 12.4412 9.966 12.4769 10.1385 12.5434C10.311 12.6098 10.4662 12.7056 10.595 12.8251L14.5212 16.218L21.0331 9.04474L21.0687 9.00907Z"
                        fill="#0EBB44"
                      />
                    </svg>
                    {saveMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="seats-section">
              <h2>Seats</h2>
              <div className="add-users">
                <h4>Add more users</h4>
                <div className="f-row aic add-user">
                  <input
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter GitHub Username"
                    type="text"
                    onKeyDown={onEnterPress}
                  />
                  <button onClick={handleAddUser} disabled={!username.trim()}>
                    Add
                  </button>
                  {error && (
                    <p className="f-row aic error">
                      <i className="material-icons open-icn">error</i> {error}
                    </p>
                  )}
                </div>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Username</th>
                    <th>Modify</th>
                  </tr>
                </thead>
                <tbody>
                  {seats.map((seat) => (
                    <tr key={seat.userGithubId}>
                      <td className="f-row aic username">
                        <a href={seat.htmlUrl} className="f-row aic username">
                          <img
                            src={seat.avatarUrl}
                            alt="user avatar"
                            className="user-avatar"
                          />
                          <span>
                            <span className="underline">{seat.username}</span>
                          </span>
                        </a>
                      </td>
                      <td>
                        <div
                          className="f-row remove-btn"
                          tabIndex={0}
                          onClick={() =>
                            removeSeat(seat.userGithubId.toString())
                          }
                        >
                          Remove
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrganizationDetail;
