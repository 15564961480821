import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import "./styles/main.scss"; // Import your main stylesheet
import BlogPost from "./pages/blogs/blogPost";
import Blog from "./pages/Blog";
import ContactUs from "./pages/ContactUs";
import Login from "./components/Login";
import Logout from "./components/Logout";
import RepositoryDetail from "./pages/RepositoryDetail";
import Repositories from "./components/Repositories";
import Organizations from "./components/Organizations";
import OrganizationDetail from "./pages/OrganizationDetail";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider } from "./components/utils/AuthContext";
import HowItWorks from "./pages/HowItWorks";
import DefaultMetaTags from "./DefaultMetaTags";
import { HelmetProvider } from "react-helmet-async";
import NotFoundPage from "./pages/NotFoundPage";
import CanonicalLink from "./components/CanonicalLink";
import UsageGuide from "./components/UsageGuide";

function App() {
  return (
    <HelmetProvider>
      <Router>
        <DefaultMetaTags />
        <CanonicalLink />
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/tos" element={<TermsOfService />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/faq" element={<HowItWorks />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route
              path="/guide"
              element={
                <ProtectedRoute>
                  <UsageGuide />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Repositories />
                </ProtectedRoute>
              }
            />
            <Route
              path="/repos"
              element={
                <ProtectedRoute>
                  <Repositories />
                </ProtectedRoute>
              }
            />
            <Route
              path="/orgs"
              element={
                <ProtectedRoute>
                  <Organizations />
                </ProtectedRoute>
              }
            />
            <Route
              path="/repo/:repoId"
              element={
                <ProtectedRoute>
                  <RepositoryDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/org/:orgId"
              element={
                <ProtectedRoute>
                  <OrganizationDetail />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
            {/* Catch-all route for 404 */}
          </Routes>
        </AuthProvider>
      </Router>
    </HelmetProvider>
  );
}

export default App;
