import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./utils/AuthContext";
import Loading from "./utils/Loading";

const ProtectedRoute = ({ children }) => {
  const { firebaseUser, authLoading } = useAuth();

  if (authLoading) {
    return <Loading />;
  }

  return firebaseUser ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
