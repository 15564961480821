// UsageGuide.js
import React from "react";
import "./Dashboard.scss";
import "./UsageGuide.scss";
import Sidebar from "./Sidebar";
import LogoNavbar from "./utils/LogoNavbar";

const UsageGuide = () => {
  return (
    <div className="f-row aifs dashboard">
      <Sidebar showTab="guide" />
      <div className="main-outer">
        <div className="f-col aifs main setup-container">
          <LogoNavbar />
          <div className="f-row aic jcsb header">
            <h1>Usage Guide</h1>
          </div>

          {/* Getting Started Section */}
          <div className="guide-section getting-started">
            <h2>Getting Started</h2>
            <p>
              devlo is your AI-powered development assistant that understands
              natural language and helps you write, review, and improve code.
              Get started in two simple steps:
            </p>
            <ol>
              <li>
                <a
                  href="https://github.com/apps/devloai/installations/select_target"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ia"
                >
                  Install devlo
                </a>{" "}
                to connect your GitHub repositories
              </li>
              <li>
                Mention <code>@devloai</code> in any issue or pull request to
                start collaborating
              </li>
            </ol>
            <div className="warning-alert">
              Always review devlo's changes and test the implementation
              thoroughly before merging.
            </div>
          </div>

          {/* Key Features Section */}
          <div className="guide-section">
            <h2>Key Features</h2>
            <div className="feature-grid">
              <div className="feature-item">
                <h4>Issue Resolution</h4>
                <p>
                  Get help implementing solutions, fixing bugs, and answering
                  technical questions.
                </p>
              </div>
              <div className="feature-item">
                <h4>Automated PR Reviews</h4>
                <p>
                  New pull requests are automatically reviewed. Configure this
                  in your repository settings.
                </p>
              </div>
              <div className="feature-item">
                <h4>Code Generation</h4>
                <p>
                  Request new features, tests, or documentation with natural
                  language.
                </p>
              </div>
              <div className="feature-item">
                <h4>Code Improvement</h4>
                <p>
                  Get suggestions for optimization, security, and best
                  practices.
                </p>
              </div>
            </div>
          </div>

          {/* Issue Resolution Section */}
          <div className="guide-section">
            <h2>Working with Issues</h2>
            <p>
              Mention <code>@devloai</code> in any GitHub issue to get help with
              implementation, debugging, or technical questions.
            </p>
            <h3>Common Commands</h3>
            <div className="example-list">
              <ul>
                <li>
                  <code>@devloai resolve this issue</code> - Let devlo analyze
                  and implement a solution
                </li>
                <li>
                  <code>@devloai give me code pointers</code> - Get guidance
                  without implementation
                </li>
                <li>
                  <code>@devloai why is this happening?</code> - Get help with
                  debugging
                </li>
              </ul>
            </div>
            <p>When resolving issues, devlo will:</p>
            <ul>
              <li>Analyze requirements and context</li>
              <li>Implement necessary changes</li>
              <li>Add or update tests</li>
              <li>Handle edge cases</li>
              <li>Explain all changes made</li>
            </ul>
          </div>

          {/* Pull Requests Section */}
          <div className="guide-section">
            <h2>Working with Pull Requests</h2>
            <p>
              devlo automatically reviews new pull requests and can help improve
              your code through comments and suggestions.
            </p>
            <h3>Common Commands</h3>
            <div className="example-list">
              <ul>
                <li>
                  <code>@devloai review this PR</code> - Get a comprehensive
                  code review
                </li>
                <li>
                  <code>@devloai generate tests</code> - Add unit tests for new
                  code
                </li>
                <li>
                  <code>@devloai optimize this code</code> - Get performance
                  improvements
                </li>
                <li>
                  <code>@devloai fix security issues</code> - Address
                  vulnerabilities
                </li>
              </ul>
            </div>

            <h3>Important Notes</h3>
            <ul>
              <li>
                <strong>Auto-review:</strong> New pull requests are
                automatically reviewed. This can be configured in your
                repository settings.
              </li>
              <li>
                <strong>Response Time:</strong> Reviews typically take under a
                minute. For longer waits, feel free to comment again.
              </li>
              <li>
                <strong>Best Practices:</strong> Always review suggestions and
                test changes before merging.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsageGuide;
