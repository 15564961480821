// Google Analytics event tracking utility functions

export const trackEvent = (eventName, eventParams = {}) => {
  if (window.gtag) {
    window.gtag('event', eventName, eventParams);
  }
};

// Video events
export const trackVideoEvent = (action, videoName) => {
  trackEvent('video_interaction', {
    action: action,
    video_name: videoName
  });
};

// CTA button clicks
export const trackCTAClick = (buttonName, location) => {
  trackEvent('cta_click', {
    button_name: buttonName,
    button_location: location
  });
};

// Section navigation
export const trackSectionView = (sectionName) => {
  trackEvent('section_view', {
    section_name: sectionName
  });
};

// Pricing plan interactions
export const trackPricingInteraction = (planName, action) => {
  trackEvent('pricing_interaction', {
    plan_name: planName,
    action: action
  });
};