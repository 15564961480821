import { initializeApp } from "firebase/app";
// import { connectAuthEmulator } from "firebase/auth";
import { getAuth } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyDk4OhqL5SfgvwaCq-GZVBjpBgeTWbXXyo",
  authDomain: "devlo.ai",
  projectId: "devlo-19ab6",
  storageBucket: "devlo-19ab6.appspot.com",
  messagingSenderId: "233603496643",
  appId: "1:233603496643:web:69380ecedc2d61a9578076",
  measurementId: "G-Q1VNEVHNY5",
};

// // Conditionally set App Check debug token for development and react-snap
// if (
//   process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN &&
//   (process.env.NODE_ENV === "development" ||
//     process.env.REACT_APP_DISABLE_APP_CHECK === "true")
// ) {
//   window.FIREBASE_APPCHECK_DEBUG_TOKEN =
//     process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;
// }

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// // Initialize App Check only if not disabled
// if (
//   typeof window !== "undefined" &&
//   process.env.REACT_APP_DISABLE_APP_CHECK !== "true"
// ) {
//   initializeAppCheck(app, {
//     provider: new ReCaptchaV3Provider(
//       "6LeuWsYpAAAAADoFEM3-GRfs-8cKcbzRMTEJ6ygs"
//     ),
//     isTokenAutoRefreshEnabled: true,
//   });
// }
// console.log("App Check Disabled:", process.env.REACT_APP_DISABLE_APP_CHECK);
// console.log(
//   "App Check Debug Token:",
//   process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN
// );

const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

// if (
//   window.location.hostname === "localhost" ||
//   window.location.hostname === "127.0.0.1"
// ) {
// connectFunctionsEmulator(functions, "localhost", 5104);
// connectFirestoreEmulator(db, "localhost", 5200);
// connectAuthEmulator(auth, "http://127.0.0.1:9099");
// }

export { app, auth, db, functions };
