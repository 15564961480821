import React, { useState } from "react";
import "./Navbar.scss";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { useAuth } from "./utils/AuthContext";
import mainLogo from "../assets/devlo.png";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const { firebaseUser } = useAuth();

  const toggleNavbar = () => setIsOpen(!isOpen); // Toggle function

  return (
    <nav className="navbar">
      <div className="navbar-mobile">
        <i className="material-icons" onClick={toggleNavbar}>
          menu
        </i>
      </div>
      <Link className="navbar-brand" to="/">
        <img src={mainLogo} alt="Logo" className="logo" />
        <span>devlo</span>
      </Link>
      <div className={`navbar-links ${isOpen ? "open" : ""}`}>
        <HashLink smooth to="/#features">
          Features
        </HashLink>
        <HashLink smooth to="/#pricing">
          Pricing
        </HashLink>
        <NavLink to="/blog">Blog</NavLink>
        {firebaseUser ? (
          <NavLink to="/dashboard" className="dashboard-link">
            Go to Dashboard
          </NavLink>
        ) : (
          <>
            <NavLink to="/login">Sign In</NavLink>
            <Link to="/login">
              <button className="cta-btn">Try devlo</button>
            </Link>
          </>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
